'use client';

import './index.css';
import {LogoSvg} from './svg/logo';

export const Loading = ({
  position = 'fixed',
}: {
  position?: 'fixed' | 'absolute';
}) => {
  return (
    <div
      className={`flex items-center justify-center ${
        position === 'fixed' ? 'fixed z-50 h-screen' : 'absolute h-full'
      } inset-0 w-screen bg-black`}
    >
      <LogoSvg className="pulse" />
    </div>
  );
};

export const LoadingBlur = ({
  position = 'fixed',
  blur = false,
}: {
  position?: 'fixed' | 'absolute';
  blur?: boolean;
}) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${blur ? 'bg-white/30 backdrop-blur-[2px]' : ''} ${position} ${
        position === 'absolute' ? 'inset-[2px]' : ''
      }`}
      style={{zIndex: 70}}
    >
      <LogoSvg className="pulse" />
    </div>
  );
};
